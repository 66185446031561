import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';
import p1 from 'assests/Photos/Celebration/Dance/1.png';
import p2 from 'assests/Photos/Celebration/Dance/2.png';
import p3 from 'assests/Photos/Celebration/Dance/3.png';




import { Typography } from '@mui/material';
import Container from 'components/Container';
import SidebarArticles from 'views/Dasara/components/SidebarArticles/SidebarArticles';

const SAPAWorkshop = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p3,
      rows: 1,
      cols: 1,
    },
  


    // {
    //   src: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   source: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   rows: 1,
    //   cols: 1,
    // },
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      If you hear Music… DANCE!
        </Typography>
        {/* <Typography
        fontSize = "x-small"
        ><br/><br/>
        CLASS 2A
        </Typography> */}

        <br />
        {/* <Typography
          variant={'h4'}
          
          align={'center'}
          fontWeight={'normal'}
        ><br/>
         If you hear Music… DANCE!
<br/>
        </Typography> */}
        <Typography variant={'subtitle1'} align={'justify'}>
        A workshop titled “Music meets Dance” was conducted by SAPA on Monday 28 th
November 2022, for the students of Pre-primary.<br/>
The presenter engaged the students in exercises, introduced the dance mudras and a few
Bharatanatyam steps. She demonstrated how mudras and dance moves are correlate to
different activities that we perform in our day-to-day life.<br/>
The students were involved in the workshop throughout and learnt a simple dance for a
song. Students were receptive and responsive to the session. The workshop was an
endeavour to kindle the interest of students in music and dance.<br/>
        </Typography>
      </Box>
      <Typography
          variant={'h4'}
          
          align={'center'}
          fontWeight={'normal'}
        ><br/>
         It is with your feet you MOVE…But it is with your heart that you DANCE…
<br/>
        </Typography>

      <Box>
        <ImageList
          variant="quilted"
          cols={3}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <SidebarArticles />
             </Box>
          
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default SAPAWorkshop;